export enum JobStatus {
    IDLE = 'IDLE',
    JOB_QUEUED = 'JOB_QUEUED',
    JOB_CREATING_MANUAL_ENTRY = 'JOB_CREATING_MANUAL_ENTRY',
    JOB_IMAGE_SCANNING = 'JOB_IMAGE_SCANNING',
    JOB_IMAGE_SCANNED = 'JOB_IMAGE_SCANNED',
    WIFI_NOT_CONNECTED = 'WIFI_NOT_CONNECTED',
    SCANNER_NOT_READY = 'SCANNER_NOT_READY',
    IOT_NOT_CONNECTED = 'IOT_NOT_CONNECTED',
    JOB_IMAGE_SCAN_ERROR = 'JOB_IMAGE_SCAN_ERROR',
    JOB_IMAGE_UPLOADING = 'JOB_IMAGE_UPLOADING',
    JOB_IMAGE_UPLOADED = 'JOB_IMAGE_UPLOADED',
    JOB_IN_PROGRESS = 'JOB_IN_PROGRESS',
    JOB_COMPLETED = 'JOB_COMPLETED',
    JOB_FAILED = 'JOB_FAILED',
}

export const isScanError = (status: JobStatus) => {
    return (status === JobStatus.WIFI_NOT_CONNECTED
        || status === JobStatus.SCANNER_NOT_READY
        || status === JobStatus.IOT_NOT_CONNECTED
        || status === JobStatus.JOB_IMAGE_SCAN_ERROR)
}

export const isImageScanningOrUploading = (status: JobStatus) => {
    return !(status === JobStatus.JOB_IMAGE_UPLOADED
        || status === JobStatus.JOB_IMAGE_SCAN_ERROR
        || status === JobStatus.JOB_IN_PROGRESS
        || status === JobStatus.JOB_COMPLETED
        || status === JobStatus.JOB_FAILED)
}
export interface IDeleteJobState {
    readonly error?: Error;
    readonly isFetching: boolean;
    readonly success: Boolean | null;
}